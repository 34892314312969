<template>
  <div class="bg-white infomations-create">

    <app-modal-confirm
      ref="information_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteInformation()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >
    <form class="form-horizontal" v-on:submit.prevent="createOrUpdate">
      <div class="card-body">

          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
            </div>
          </div>
          <validation-provider
            name="status_publishing"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.status")}} <span
                class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
                <app-radio v-for="(item, index) in INFORMATION_CONSTANT.status"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="entry.status_publishing"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.mansion") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-select
                name="mansion_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.title") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-input
                name="title"
                rules="required"
                type="string:12"
                :class="['flex-fill']"
                v-model="entry.title"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.description") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-text
                name="description"
                rules="required"
                type="string:50"
                :class="['flex-fill']"
                v-model="entry.description"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.display_order") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-input
                name="display_order"
                :class="['flex-fill']"
                v-model="entry.display_order"
                type="number-length:4"
                :rules="VALIDATE.V_SMALL_INT_REQUIRED"
              />
            </div>
          </div>
          <validation-provider
            name="image_path"
            :rules="!idEditing ? '' : ''"
            ref="file_preview"
            v-slot="{ errors, invalid, validated }"
          >
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.image_path") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-single-file-input accept="image/jpeg, image/png" name="image_path"
                                     :imageSrc="entry.image_path" v-model="entry.image"
                                     @input="entry.image = $event" :allowedExtension="allowedExtension"></app-single-file-input>
              <label v-if="invalid && validated" class="error">
                {{ errors[0] }}
              </label>
            </div>
          </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.web_url") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9">
              <app-input
                name="web_url"
                :class="['flex-fill']"
                v-model="entry.url"
                type="string512"
                rules="url"
              />
              <label class="notifi-resetpass m-t-20">{{$t('information.url_label')}}</label>
            </div>

          </div>
          <validation-provider
            name="information_type"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("information.information_type")}} <span
                class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9 align-self-center grid mt-1">
                <app-radio v-for="(item, index) in INFORMATION_CONSTANT.types"
                           :isMarginLeft="true" :key="item.id" :label="item.name"
                           :val="item.id" v-model="entry.information_type"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>

          <div class="form-group" v-if="entry.information_type != 2">
            <div class="row p-0 justify-content-between">
              <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                <label class="col-sm-10">{{ $t("information.detail_content") }}</label>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                <label class="col-sm-12 pl-0">{{ $t("facilities.html_preview") }}</label>
              </div>
            </div>
            <div class="row p-0 justify-content-between">
              <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                <app-summernote v-if="loading" max-length="5120"
                  @input="entry.contents = $event" :val="entry.contents">
                </app-summernote>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                <div class="html-preview">
                  <div v-html="entry.contents"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">注文受付期限
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
                <app-input
                  name="limit_order_day"
                  type="number-length:2"
                  :class="['flex-fill']"
                  v-model="entry.limit_order_day"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">日前
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
                <app-input
                  name="limit_order_hour"
                  type="string:5"
                  rules="hour_minute"
                  :class="['flex-fill']"
                  v-model="entry.limit_order_hour"
                />
              </div>
              <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">時前まで
              </label>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">キャンセル期限
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
                <app-input
                  name="limit_cancel_day"
                  type="number-length:2"
                  :class="['flex-fill']"
                  v-model="entry.limit_cancel_day"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">日前
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
                <app-input
                  name="limit_cancel_hour"
                  type="string:5"
                  rules="hour_minute"
                  :class="['flex-fill']"
                  v-model="entry.limit_cancel_hour"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">時前まで
              </label>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">配送料
              </label>
              <div class="col-xl-1 col-lg-3 col-sm-5">
                <app-input
                  name="ship_fee"
                  type="number-length:11"
                  :class="['flex-fill']"
                  v-model="entry.ship_fee"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">円
              </label>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">メッセージ
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9">
                <app-text
                  name="note"
                  type="string:512"
                  :class="['flex-fill']"
                  v-model="entry.note"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">運営時間
                <span class="app-require">＊</span>
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
<!--                <app-input-->
<!--                  name="opening_time"-->
<!--                  type="string:5"-->
<!--                  rules="required|hour_minute"-->
<!--                  :class="['flex-fill']"-->
<!--                  v-model="entry.opening_time"-->
<!--                />-->
                <app-select
                  name="opening_time"
                  :rules="`required|opening_time:${entry.closing_time}`"
                  :options-data="meta.opening_time"
                  v-model="entry.opening_time"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label text-center">~
              </label>
              <div class="col-xl-1 col-lg-1 col-sm-1">
<!--                <app-input-->
<!--                  name="closing_time"-->
<!--                  type="string:5"-->
<!--                  rules="required|hour_minute"-->
<!--                  :class="['flex-fill']"-->
<!--                  v-model="entry.closing_time"-->
<!--                />-->
                <app-select
                  name="closing_time"
                  rules="required"
                  :options-data="meta.closing_time"
                  v-model="entry.closing_time"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">時間単位
                <span class="app-require">＊</span>
              </label>
              <div class="col-xl-1 col-lg-3 col-sm-5">
                <app-input
                  name="unit_time"
                  type="number-length:3"
                  :class="['flex-fill']"
                  v-model="entry.unit_time"
                  rules="required"
                />
              </div>
              <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">分
              </label>
            </div>
          </div>
        <div class="card-footer row justify-content-end gr-button">
          <button type="button" :disabled="!valid && validated"  class="btn btn-default m-r-10" @click="handlerCancelButton">
            {{ $t('common.cancel') }}
          </button>
          <button type="button" :disabled="!valid && validated" class="btn btn btn-danger m-r-10" @click="handleDeleteInformation" v-if="idEditing">
            {{ $t('apartments.delete') }}
          </button>
          <button  class="btn btn-primary" :disabled="!valid && validated" type="submit">
            {{idEditing ?  $t('common.save') :  $t('common.create')}}
          </button>
        </div>
      </div>
    </form>
    </validation-observer>
  </div>
</template>

<script>

  import {ROUTES} from "@constants";

  export default {
    name: "InformationCreateOrEdit",

    data() {
      const id = this.$route.params.id
      return {
        meta: {
          apartments: [],
          opening_time:[],
          closing_time:[],
        },
        entry: {
          contents : "",
          information_type : 0
        },
        idEditing: id,
        loading: true,
        allowedExtension : ['image/jpeg', 'image/png']
      }
    },
    async created() {
      if (this.idEditing) {
        this.loading = false;
        await this.$request.get(this.ENDPOINT.INFORMATION_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue();
          } else {
            this.entry = res.data.data
            this.loading = true;
          }
        })
      }
    },
    mounted: async function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
          closing_time: res.data.closing_time,
          opening_time: res.data.opening_time,
        }
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })
    },
    methods :{
      async createOrUpdate() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";

        if (this.idEditing) {
          res = await this.$request.patch(this.ENDPOINT.INFORMATION_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.INFORMATION_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      handleDeleteInformation() {
        this.$refs.information_delete_modal.show();
      },
      async deleteInformation() {
        const res = await this.$request.delete(this.ENDPOINT.INFORMATION_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.information_delete_modal.hide();
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.INFORMATION_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      redirectDefaultValue() {
        this.$router.push({name: this.ROUTES.ADMIN.INFORMATION_LIST,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all',
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
